.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

body {
    color: #777;
}

.container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.content {
    padding-left: 2vw;
    padding-right: 2vw;
    height: 92.5vh;
    background-color: rgba(255, 255, 255, 0.9);
    overflow: auto;
}

section {
    margin-top: 1em;
}

section, .block {
    /* background-color: rgba(255, 255, 255, 0.95);
    border-radius: 1em; */
    box-sizing: border-box;
    padding: 0 2vw;
}
