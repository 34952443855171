.level-card {
    margin: 0.25em !important;
    padding: 0.4em;
    display: inline-block;
    background-color: #FFF !important;
}

.level-card.selected {
    background-color: #CCCCCC !important;
}
