.detail-list-item {
    margin-bottom: 0.5em;
    padding: 0.5em;
    border: 2px solid black;
    background-color: white;
    position: relative;


}

.detail-list-item img {
    height: 3em;
    width: 3em;
    float: left;
    padding-right: 0.5em;
}

.detail-list-item.lower-right {
  position: absolute;
  right: 0.2em;
  bottom: 0.1em;
}

.detail-list-item.upper-right {
  position: absolute;
  top: 0.4em;
  right: 0.2em;
}
