body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

body {
    color: #777;
}

.container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.content {
    padding-left: 2vw;
    padding-right: 2vw;
    height: 92.5vh;
    background-color: rgba(255, 255, 255, 0.9);
    overflow: auto;
}

section {
    margin-top: 1em;
}

section, .block {
    /* background-color: rgba(255, 255, 255, 0.95);
    border-radius: 1em; */
    box-sizing: border-box;
    padding: 0 2vw;
}

#lightbox {
    position: fixed;
    top: 3em;
    width: 90vw;
    height: 80vh;
    left: 5vw;
    z-index: 2001;
    background: white;
    border: 1px solid black;
}

#lightbox-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    background: black;
    opacity: 0.8;
    z-index: 2000;
    top: 0;
}

.content {
  padding-left: 2vw;
  padding-right: 2vw;
}

.level-card {
    margin: 0.25em !important;
    padding: 0.4em;
    display: inline-block;
    background-color: #FFF !important;
}

.level-card.selected {
    background-color: #CCCCCC !important;
}

.question-container  {
    text-align: center;
}

.confirm-button {
    position: absolute;
    bottom: 5vh;
    left: 5vw;
    width: 90vw;
}

/* .question-row {
    position: absolute;
    top: 20vh;
    left: 0;
} */

.detail-list-item {
    margin-bottom: 0.5em;
    padding: 0.5em;
    border: 2px solid black;
    background-color: white;
    position: relative;


}

.detail-list-item img {
    height: 3em;
    width: 3em;
    float: left;
    padding-right: 0.5em;
}

.detail-list-item.lower-right {
  position: absolute;
  right: 0.2em;
  bottom: 0.1em;
}

.detail-list-item.upper-right {
  position: absolute;
  top: 0.4em;
  right: 0.2em;
}

