.question-container  {
    text-align: center;
}

.confirm-button {
    position: absolute;
    bottom: 5vh;
    left: 5vw;
    width: 90vw;
}

/* .question-row {
    position: absolute;
    top: 20vh;
    left: 0;
} */
