#lightbox {
    position: fixed;
    top: 3em;
    width: 90vw;
    height: 80vh;
    left: 5vw;
    z-index: 2001;
    background: white;
    border: 1px solid black;
}

#lightbox-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    background: black;
    opacity: 0.8;
    z-index: 2000;
    top: 0;
}
